import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {TranslocoModule} from '@jsverse/transloco';
import {Subscription} from 'rxjs';
import {LuminaStatus, LuminaStatusInfoTo} from 'types';
import {LuminaServerStatusComponent} from '../../access-control/lumina-import/lumina-importing-progress/lumina-server-status.component';
import {LuminaInfoGQL} from '../../lumina.generated';
import {DialogTitleComponent} from '../../shared/components/dialog-title/dialog-title.component';

@Component({
  selector: 'alc-lumina-status-dialog',
  standalone: true,
  imports: [DialogTitleComponent, MatDialogModule, LuminaServerStatusComponent, TranslocoModule],
  templateUrl: './lumina-status-dialog.component.html',
  styleUrls: ['./lumina-status-dialog.component.scss']
})
export class LuminaStatusDialogComponent implements OnDestroy {
  LuminaStatus = LuminaStatus;

  private luminaStatus$: Subscription;

  constructor(
    private dialogRef: MatDialogRef<LuminaStatusDialogComponent>,
    private luminaInfoGQL: LuminaInfoGQL,
    @Inject(MAT_DIALOG_DATA) public dialogData: LuminaStatusInfoTo
  ) {
    this.luminaStatus$ = this.luminaInfoGQL.subscribe().subscribe(response => {
      this.dialogData = response.data.luminaInfo;
      if (this.dialogData.luminaStatus !== LuminaStatus.Importing && this.dialogData.luminaStatus !== LuminaStatus.Error) {
        this.dialogRef.close();
        location.reload();
      }
    });
  }

  ngOnDestroy(): void {
    this.luminaStatus$.unsubscribe();
  }
}
