@for (announcement of announcementPreviews; track announcement.id) {
  <alc-announcement-list-item [announcement]="announcement" (dismissClicked)="dismissPreview(announcement)" />
}

@if (!announcementPreviews.length) {
  @for (announcement of announcements; track announcement.id) {
    @if (!announcement.dismissed) {
      <alc-announcement-list-item [announcement]="announcement" (dismissClicked)="dismissAnnouncement(announcement)" />
    }
  }
}
