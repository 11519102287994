import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatIconButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {MatTooltip} from '@angular/material/tooltip';
import {TranslocoPipe} from '@jsverse/transloco';
import {EllipsisTooltipDirective} from '../../../shared/directives/ellipsis-tooltip.directive';
import {AnnouncementItem} from './announcement-item.interface';

@Component({
  selector: 'alc-announcement-list-item',
  standalone: true,
  imports: [MatIcon, MatIconButton, TranslocoPipe, EllipsisTooltipDirective, MatTooltip],
  templateUrl: './announcement-list-item.component.html',
  styleUrl: './announcement-list-item.component.scss'
})
export class AnnouncementListItemComponent {
  @Input() announcement: AnnouncementItem;
  @Output() dismissClicked = new EventEmitter<AnnouncementItem>();
}
