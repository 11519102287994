import {Component, OnInit} from '@angular/core';
import {AnnouncementTO, AnnouncementType} from 'api/entities';
import {Settings} from 'luxon';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {AnnouncementStatus, getAnnouncementStatus} from '../../admin/announcements/get-announcement-status.static';
import {LocalStorageKeys} from '../../shared/constants/local-storage-keys.constant';
import {AnnouncementsEntity} from '../../shared/entities/announcements/announcements.entity';
import {UserSessionService} from '../../shared/entities/auth/user-session.service';
import {SystemEntity} from '../../shared/entities/system/system.entity';
import {Alcedo7User, selectedClientChange$} from '../../shared/entities/user/avelon-user.service';
import {AlcedoUserLanguages} from '../../shared/properties/user/user-languages.enum';
import {debounce, remove} from '../../shared/services/array.service';
import {BrandService} from '../../shared/services/brand.service';
import {LuxonService} from '../../shared/services/luxon.service';
import {orderByLocale} from '../../shared/services/order-by-locale.service';
import {UserLocaleService} from '../auth/user-locale.service';
import {systemInfo} from '../guards/lumina-status.guard';
import {AnnouncementItem} from './announcement-list-item/announcement-item.interface';
import {AnnouncementListItemComponent} from './announcement-list-item/announcement-list-item.component';
import {previewAnnouncementsChange, previewAnnouncementsDismiss} from './preview-announcements.const';

@Component({
  selector: 'alc-announcements-list',
  standalone: true,
  imports: [AnnouncementListItemComponent],
  templateUrl: './announcements-list.component.html',
  styleUrl: './announcements-list.component.scss'
})
export class AnnouncementsListComponent implements OnInit {
  announcements: AnnouncementItem[] = [];
  announcementPreviews: AnnouncementItem[] = [];
  private announcementsListenersSet: boolean;

  private dismissedIds: number[];
  private loading$: Subscription;
  private platformTitle: string;
  private storageListenerDebounced = debounce((event: StorageEvent) => this.storageListener(event), 1000);

  constructor(
    private announcementsEntity: AnnouncementsEntity,
    private systemEntity: SystemEntity
  ) {}

  ngOnInit(): void {
    previewAnnouncementsChange.subscribe(a => {
      const preview = this.announcementPreviews.find(p => a.id === p.id);
      remove(this.announcementPreviews, preview); // Remove preview when the user previews an announcement, then he edits and previews it from dialog
      this.announcementPreviews.push({id: a.id, message: this.getMessage(a)});
    });
    previewAnnouncementsDismiss
      .pipe(filter(id => this.announcementPreviews.some(a => a.id === id)))
      .subscribe(id => remove(this.announcementPreviews, {id}));

    selectedClientChange$.subscribe(() => {
      if (
        this.announcementsListenersSet ||
        UserSessionService.isSlideShowOrReporting() ||
        UserSessionService.isLuminaOperationCenter(systemInfo.deploymentType)
      ) {
        return;
      }
      this.announcementsListenersSet = true;
      this.systemEntity.getStaticInfo().subscribe(systemInfo => {
        Settings.defaultLocale = Alcedo7User.currentUser.language;
        this.platformTitle = BrandService.getPlatformTitle(systemInfo.deploymentType);
        this.loadAnnouncements();
        window.addEventListener('storage', this.storageListenerDebounced);
        setInterval(() => this.loadAnnouncements(), 1000 * 60 * 10);
      });
    });
  }

  dismissPreview(announcement: AnnouncementItem) {
    remove(this.announcementPreviews, announcement);
    previewAnnouncementsDismiss.next(announcement.id);
  }

  dismissAnnouncement(announcement: AnnouncementItem) {
    announcement.dismissed = true;
    this.dismissedIds.push(announcement.id);
    localStorage.setItem(LocalStorageKeys.Announcements.dismissedIds, JSON.stringify(this.dismissedIds));
  }

  private loadAnnouncements() {
    this.dismissedIds = JSON.parse(localStorage.getItem(LocalStorageKeys.Announcements.dismissedIds)) || [];

    this.loading$?.unsubscribe();
    this.loading$ = this.announcementsEntity.getAnnouncements(false).subscribe(announcements => {
      this.announcements = orderByLocale(announcements, 'visibleUntil', true)
        .filter(
          a =>
            a.enabled &&
            getAnnouncementStatus(a) === AnnouncementStatus.ACTIVE &&
            (!a.showOnlyToPrivilegedUsers || Alcedo7User.currentUser.showPrivilegedAnnouncements)
        )
        .map(a => ({
          id: a.id,
          message: this.getMessage(a),
          dismissed: this.dismissedIds.some(id => id === a.id)
        }));
    });
  }

  private getMessage(announcement: AnnouncementTO): string {
    const message =
      announcement.i18n[UserLocaleService.getLanguage(Alcedo7User.currentUser.language)] || announcement.i18n[AlcedoUserLanguages.ENGLISH];
    const startDate = announcement.maintenanceFrom ? LuxonService.parse(announcement.maintenanceFrom, LuxonService.DATETIME_SHORT) : '';
    const endDate = announcement.maintenanceUntil ? LuxonService.parse(announcement.maintenanceUntil, LuxonService.DATETIME_SHORT) : '';
    return (
      announcement.type === AnnouncementType.INCIDENT ? message : message.replaceAll('{start}', startDate).replaceAll('{end}', endDate)
    ).replaceAll('{platform}', this.platformTitle);
  }

  private storageListener(event: StorageEvent): void {
    if (event.storageArea?.[LocalStorageKeys.Announcements.dismissedIds]) {
      this.loadAnnouncements();
    }
  }
}
